@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:wght@400;500;600;700;800&display=swap');
* {
  font-family:'Montserrat', 'Roboto', sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;

}
.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
a {
  text-decoration: none;
  color: #03873d;
}
a.bold {
  font-weight:bold;
}
a.black {
  color:#000;
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
p, li {
  line-height: 1.8;
}
.wrapper li {
  list-style-image: url('./images/bullet_marker.svg');
  padding-left:15px;
}
.wrapper li::marker {
  font-size:1.4rem;
}
.numbers li {
  list-style-image: none;
}
.numbers li::marker {
  font-size:1rem;
  color:#03873d;
}
.numbers a {
  text-decoration: underline;
}
h1 {
  color:#222222;
  font-family: 'Fjalla One', sans-serif;
  font-size:50px;
}
h2 {
  color:#03873d;
  font-size:36px;
  font-family: 'Fjalla One', sans-serif;
  margin:15px 0;
}
h2.large {
  font-size:50px;
}
h3 {
  color:#222222;
  font-family: 'Fjalla One', sans-serif;
  margin:15px 0;
  font-size:30px;
}
h4.fjalla {
  font-size:22px;
  font-family:'Fjalla One', 'Roboto', sans-serif;
  font-weight:100;
  margin:15px 0;
}
h4.fjalla a {
  font-size:22px;
  font-family:'Fjalla One', 'Roboto', sans-serif;
  font-weight:100;
  margin:15px 0;
}
h4 {
  font-size:18px;
  font-family:'Montserrat', 'Roboto', sans-serif;
  font-weight: bold;
  margin:15px 0;
}
.disclosure {
  font-size:14;
  padding-bottom:30px;
}
.small-text {
  font-size:12px;
}
.h-green h3 {
  color:#03873d;
}
.white {
  color:#fff;
}
.orange {
  color:#03873d;
}
.green {
  color:#03873d;
}
.dark-grey {
  color:#888888;
}
.black {
  color:#222222;
}
.bg-grey {
  background-color:#f9f9f9;
}
.bg-orange {
  background-color:#de7205!important;
  color:#fff;
}
.bg-dark-grey {
  background-color:#DEDEDE;
}
.bg-white {
  background-color:#fff;
}
.center {
  text-align: center!important;
}
.right {
  text-align: right!important;
}
.left {
  text-align: left!important;
}
sup {
  font-size:10px;
}
.sup i strong {
  font-size:10px;
  vertical-align: super;
  font-weight:normal;
}
.sup h2 i strong {
  position:relative;
  top:-10px;
  font-style:normal;
}
.title-sup {
  font-size:16px;
  vertical-align: super;
  position: relative;
  top:-17px;
}
hr {
  width:80px;
}
hr.orange {
  color:#de7205;
  background-color:#de7205;
  border: 2px solid #de7205;
  margin: 0;
}
hr.green {
  color:#de7205;
  background-color:#de7205;
  border: 2px solid #de7205;
  margin: 0;
}
hr.white {
  color:#fff;
  background-color:#fff;
  border: 2px solid #fff;
  margin: 0;
}
hr.center {
  margin:0 auto;
}
button.btn-green-alt {
  background-color:#03873d;
  color:#fff;
  padding:6px 20px;
  text-transform: uppercase;
  display: inline-block;
  border:#03873d 1px solid;
  transition: .5s;
  font-size:18px;
  font-weight:600;
}
button.btn-green-alt:hover {
  background-color:#fff;
  color:#03873d;
  
}
.btn-green a {
  background-color:#03873d;
  color:#fff;
  padding:6px 20px;
  text-transform: uppercase;
  display: inline-block;
  border:#03873d 1px solid;
  transition: .5s;
  font-size:18px;
  font-weight:600;
}
.btn-green a:hover {
  background-color:#fff;
  color:#03873d;
  
}
.btn-orange a {
  background-color:#de7205;
  color:#fff;
  padding:6px 20px;
  text-transform: uppercase;
  display: inline-block;
  border:#de7205 1px solid;
  transition: .5s;
  font-size:18px;
  font-weight:600;
}
.btn-orange a:hover {
  background-color:transparent;
  color:#fff;
  
}
.btn-ghost-green a {
  color:#03873d;
  padding:6px 20px;
  text-transform: uppercase;
  display: inline-block;
  border:#03873d 1px solid;
  background-color:#fff;
  transition: .5s;
  font-size:18px;
  font-weight:600;
  line-height:1.2;
}
.btn-ghost-green a:hover {
  background-color:#03873d;
  color:#fff;
}
.btn-ghost-white a {
  color:#fff;
  padding:6px 20px;
  text-transform: uppercase;
  display: inline-block;
  border:#fff 1px solid;
  transition: .5s;
  font-size:18px;
  font-weight:600;
}
.btn-ghost-white a:hover {
  background-color:#fff;
  color:#de7205;
}
.btn-white a {
  color:#222222;
  background-color:#fff;
  padding:6px 20px;
  text-transform: uppercase;
  display: inline-block;
  border:#fff 1px solid;
  transition: .5s;
  font-size:18px;
  font-weight:600;
}
.btn-white a:hover {
  background-color:transparent;
  color:#fff;
}
.pd-top-20 {
  padding-top:20px;
}
.pd-tb-30 {
  padding:30px 0;
}
.pd-tb-50 {
  padding:50px 0;
}
.pd-50 {
  padding:50px;
}
.mg-top-20 {
  margin-top:20px!important;
}
.mg-top-40 {
  margin-top:40px;
}
.mg-top-50 {
  margin-top:50px!important;
}
.mg-top-80 {
  margin-top:80px!important;
}
.mg-top-100 {
  margin-top:100px!important;
}
.mg-left-50 {
  margin-left:50px!important;
}
.mg-bottom-50 {
  margin-bottom:50px!important;
}
.mg-bottom-20 {
  margin-bottom:20px!important;
}
.mg-center {
  margin-left:auto;
  margin-right:auto;
}
.mg-0 {
  margin:0;
}
.mg-tb-15 {
  margin-top:15px;
  margin-bottom:15px;
}
.mg-tb-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.width-150 {
  width:150px;
}
.width-50 {
  width:50%;
}
.z-index-1 {
  z-index:1;
  position: relative;
}
.bg-bottom {
  background-position: 0 bottom;
}
.bg-top {
  background-position: 0 top;
}
.bg-center {
  background-position: 0 center;
}
.max-800 {
  max-width:800px!important;
}
.max-600 {
  max-width:800px!important;
}
.max-500 {
  max-width:500px!important;
}
.max-400 {
  max-width:400px!important;
}
.mg-auto {
  margin:0 auto;
}
.p-center p {
  text-align: center;
}
.p-center ul {
  max-width:400px;
  margin:0 auto;
}
.logo-area {
  display:flex;
  align-items: center;
}
.logo-area a {
  display:inline-block;
}
.logo-area img {
  width:175px;
}
.container {
  max-width:1200px;
  margin:0 auto;
}
.announcements-bar {
  background-color:#555;
}
.accouncement {
  color:#fff;
  text-align: center;
  padding:10px 20px;
  font-size:15px;
}
.accouncement a {
  color:#fff;
  text-decoration: underline;
}
.top-header {
  background-color:#222222;
  color:#fff;
}
.top-header a {
  color:#DEDEDE;
  text-decoration: none;
  font-size:16px;
  display:flex;
  align-items: center;
  gap:6px;
}
.top-header ul {
  list-style-type: none;
  display:flex;
  justify-content: flex-end;
  padding:0;
}
.top-header li {
  padding:8px 18px;
  list-style-image: none;
}
.top-header img {
  height:16px;
  width: auto;
}
.main-header {
  min-height:100px;
  display:flex;
}
.main-header .logo-area {
  width:40%;
}
.main-header .main-nav {
  width:60%;
}
.main-header nav ul {
  display:flex;
  list-style-type:none;
  margin:0;
  padding:0;
}
.main-header nav ul li {
  list-style-image: none;
  width: 100%;
  text-align: center;
  margin-top:36px;
  position: relative;
}
.main-header nav ul li a {
  display:inline-block;
  padding:15px 0;
  color: #666666;
  text-transform:uppercase;
  font-size:16px;
  font-weight:700;
  width:100%;
  border-bottom:4px solid #fff;
}
.main-header nav.main-nav ul li:hover a {
  background-color:#f9f9f9;
  border-bottom:4px solid #de7205;
}
/* Business */
#main_8 {
  left:-475px;
}
/* Personal */
#main_9 {
  left:-715px;
}
/* About */
#main_10 {
  left:-335px;
}
#main_10 .col {
  width:575px;
}
#main_10 ul {
  columns: 2;
  display:block;
}
#main_10 ul li {
  width:250px;
}
.mega-menu {
  /* display:none; */
  position:absolute;
  z-index: 4;
  background-color:#fff;
  display:none;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
}
.main-nav ul li:hover .mega-menu {
  display:flex;
}
.mega-menu .col {
  width:250px;
  padding:50px 25px;
  box-sizing: border-box;
}
.mega-menu .col h4 {
  text-align: left;
  margin-bottom:4px;
}
.mega-menu .col hr {
  width:125px;
  border-width: 1px;
}
.mega-menu .col ul {
  display:flex;
  flex-direction: column;
  margin-top:30px;
}
.mega-menu .col ul li {
  text-align: left;
  margin-top:0;
}
.mega-menu .col ul li a {
  text-transform: unset;
  border-bottom: 0;
  padding:6px 4px;
  font-size:14px;
  border-bottom:0!important;
  background-color:unset!important;
}
.mega-menu .col ul li:hover a {
  border-bottom:0;
  color:#03873D;
  font-weight:bold;
}
.mega-menu .first {
  order:-1;
  background-color:#f9f9f9;
}
.mega-menu .btn-white a {
  color:#fff;
  background-color:#03873D!important;
  padding:6px 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  display: inline-block;
  border:#fff 1px solid;
  transition: .5s;
  font-size:14px;
  font-weight:600;
  border:1px solid #03873D!important;
  margin-top:40px;
}
.mega-menu .btn-white a:hover {
  background-color:#fff!important;
  color:#03873D;
} 
.btn-white-nav a {
  color:#000!important;
  background-color:#fff!important;
  padding:6px 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  display: inline-block;
  transition: .5s;
  font-size:14px;
  font-weight:600;
  border:1px solid #fff!important;
  margin-top:40px;
  text-align:center;
}
.btn-white-nav a:hover {
  background-color:#03873D!important;
  color:#fff!important;
} 
.mobile-menu ul {
  flex-direction: column;;
}
/* Mobile Menu */
.hamburger-icon {
  cursor: pointer;
  display: none; /* Hidden by default, shown in media query */
}

/* Hamburger bars */
.hamburger-icon div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px 0 5px auto;
}
.menu {
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
  right: -100%; /* Start off-screen */
  width: 100%;
  height: 100vh;
  background-color: #222222;
  transition: right 0.3s;
  z-index: 10;
}

.menu.open {
  right: 0; /* Slide into view */
}
.submenu {
  position: absolute;
  top: 0;
  right: -100%; /* Start off-screen */
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Different background for distinction */
  transition: left 0.3s;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
.mobile-menu .submenu .col {
  background-color:#fff;
  padding:20px 20px;
}
.submenu .first{
  order:-1;
  background-color: #03873D!important;
  color:#fff;
}
.submenu .first a {
  color:#fff;
}
.submenu.open {
  right: 0; /* Slide into view */
}
.mobile-menu {
  display:none;
}
.main-header nav.mobile-menu ul li {
  margin-top:0;
  text-align:left;
}
.main-header nav.mobile-menu ul li a {
  border-bottom:0;
  padding:4px 0;
}
.main-header nav.mobile-menu h4 {
  margin:8px 0;
}
.main-header nav.mobile-menu hr {
  margin-bottom:8px;
}
.top-header-mobile {
  display:none;
}
@media (max-width: 768px) {
  .main-header nav ul {
    position: relative;
  }
  .main-header nav ul li {
    position: unset;
  }
  .main-nav {
    display:none;
  }
  .open > ul {
    margin-top:50px!important;
  }
  .mobile-menu {
    display:flex;
    width:60%;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    margin-right:30px;
  }
  .hamburger-icon {
    display: block;
    
  }
  #root {
    overflow-x: hidden;
    position: relative;
  }
  .close-btn, .back-btn {
    position: absolute;
    top: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 200;
    font-size:16px;
    color:#fff;
    /* Additional styling */
  }
  
  .close-btn {
    right: 10px;
    top:15px;
  }
  
  .back-btn {
    left: 10px;
    top:-32px;
  }
  .top-level-link {
    color:#fff!important;
    padding:8px 20px!important;
  }
  .top-level-li {
    border-left:4px solid #222;
  }
  .top-level-li:hover {
    border-left:4px solid #03873D;
  }
  .right-arrow::after {
    content: '\203A';
    display: inline-block;
    float: right;
    font-size:16px;
    position:absolute;
    right:20px;
  }
  .top-header-mobile {
    display:flex;
  }
  .mobile-account-login {
    background-color: #03873D;
    width:65%;
  }
  .mobile-top-right {
    width:35%;
    background-color: #222;
    text-align: right;
  }
  .mobile-top-right a {
    padding:10px;
    display: inline-block;
  }
  .mobile-top-right img {
    max-height:18px;
  }
  .mobile-account-login a {
    color:#fff;
    display:inline-block;
    width:100%;
    padding:10px;
  }
  .top-header {
    display:none;
  }
}

/* Heros */

.hero-banner {
  position:relative;
}
.inner-container {
  display: flex;
  position: relative;
  z-index: 3;
  max-width:1400px;
  margin:0 auto;
  width:100%;
}
.no-account-section {
  max-width:1300px;
}
.hero-banner .hero {
  min-height:550px;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  display:flex;
  align-items:center;
  position: relative;
}
.hero-banner #hero-id-8 {
  background-position:50% 10%;
}
#lendio-hero-id-67 {
  background-position:50% 10%;
}
.hero-banner h1 {
  line-height: 63px;
  margin-top:0;
}
.hero-banner h1, .hero-banner p {
  color:#fff;
  position:relative;
}
.hero-slider li {
  padding-left:0;
  list-style-image:none;
}
.hero-image img {
  width:100%;
  max-width:500px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 15px #000;
}
.homepage-hero-buttons {
  display:flex;
  gap:10px;
}
.hero-mobile {
  display:none;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px)!important;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px)!important;
}
.custom-home-dot-list-style .inactive {
  opacity: .4;
}
.grad-overlay {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0.8) 50%, rgba(255,255,255,0) 100%);
  position: absolute;
  width:100%;
  height:100%;
}
.grad-overlay-cover {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.98));
  position: absolute;
  width:100%;
  height:100%;
}
.hero-banner .inner-hero {
  max-width: 400px;
  padding-right:100px;
  margin:0 0 0 50px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1700px) {
  .hero-banner .inner-hero {
    max-width:600px;
  }
}
/* .banner-slider .inner-hero {
  margin:0 0 0 350px;
} */
.hero-banner .btn-green {
  font-size:14px;
}
/* I'm Looking For */
.looking-item {
  display:flex;
  padding: 0 100px;
  align-items: center;
  gap:30px;
}
.look-info {
  width:50%;
}
.look-image {
  width:50%;
}
.look-image img {
  width:100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  object-fit: cover;
  object-position:center;
}
.looking-for .react-multi-carousel-dot-list {
  align-items:start;
  top:0;
  bottom:unset!important;
  width:100%;
  border-bottom:1px solid #222222;
}
.looking-for .react-multi-carousel-dot-list button {
 width:100%;
 padding:15px 0;
}
.looking-for .react-multi-carousel-track {
  margin-top:100px;
}
.looking-for {
  padding:75px 0;
}
.looking-for .container {
  text-align:center;
}
.looking-for .container hr {
  margin:0 auto;
}
.looking-for .container select {
  margin:15px 0;
}
select {
  border-top:0;
  border-right:0;
  border-left:0;
  font-size:24px;
  padding:8px 15px;
  text-align: center;
}
option {
  font-size:24px;
}
.btn-side {
  display:flex;
}
/* Call to Actions */
.cta-wrapper {
  background-color:#f9f9f9;
  padding:75px 0;
}
.cta-info {
  text-align: center;
}
.cta-info hr {
  margin:0 auto;
}
.cta-info ul li {
  text-align: left;
}
.col-2 .cta-box {
  flex-wrap: wrap;
}
.col-2 .cta-box .cta {
  width:48%;
}
.col-3 .cta-box {
  flex-wrap: wrap;
}
.col-3 .cta-box .cta {
  width:31%;
}
.col-3 .cta-box .cta-info {
  padding:45px;
}
.img-250 .cta-box .cta-image {
  min-height:250px;
}
.cta-box {
  display:flex;
  gap:25px;
}
.cta-box .cta {
  border-bottom:10px solid #de7205;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
  width:100%;
  background-color:#fff;
}
.cta-box .cta-info {
  padding:45px 70px;
}
.cta-box .cta-image {
  min-height:300px;
  background-size:cover;
  background-repeat: no-repeat;
}
.home .cta-box #cta-id-2 {
  background-position:20% 50%;
}
/* Alt Call to Actions */
.alt-image-color {
  padding:50px 0;
  background-color:#f9f9f9;
}
.alt-cta {
  display:flex;
  gap:55px;
  padding:50px 0;
}
.alt-cta-image {
  width:65%;
  display:flex;
  align-items: center;
}
.left.alt-cta-orange img {
  box-shadow: -10px -10px #de7205;
}
.left.alt-cta-green img {
  box-shadow: -10px -10px #03873d;
}
.right.alt-cta-orange img {
  box-shadow: 10px -10px #de7205;
}
.right.alt-cta-green img {
  box-shadow: 10px -10px #03873d;
}
.alt-cta-info {
  width:35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding:0 20px;
}
.alt-cta-image img {
  width:100%;
}

/* Icon CTA */

.icon-cta {
  background-color: #f9f9f9;
  padding:60px 0;
}
.cta-icon-box {
  display:flex;
  gap:75px;
  flex-wrap:wrap;
}
.iconcta {
  border-bottom:10px solid #de7205;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
  width:calc(33.3% - 120px);
  background-color:#fff;
  padding:50px 35px;
  text-align: center;
}
.iconcta hr {
  margin:0 auto;
}
.iconcta-image img{
  max-height:70px;
}
.iconcta-info {
  display: flex;
  flex-direction: column;
  height:80%;
}
.iconcta-info .btn-ghost-green {
  margin:auto 0 0;
}
/* Rates CTA */
.rates-cta {
  padding:75px 0;
  position:relative;
}
.rates-cta .container {
  z-index: 3;
  position: relative;
}
.rates-cta-flex {
  display:flex;
  align-items:center;
  gap:50px;
}
.rates-cta-item {
  width:50%;
}
.rates-cta-item h5, .rates-cta-item h2, .rates-cta-item p {
  color:#fff;
}
.rates-cta-box {
  background-color:#fff;
  border-bottom:8px solid #de7205;
}
.rates-cta-box .rates-cta-type {
  background-color:#03873d;
  color:#fff;
  text-align:center;
  padding:10px 0;
  font-weight:bold;
  font-size:18px;
}
.rates-cta-info h2 {
  color:#03873d;
  font-size:70px;
  font-weight:normal;
  text-align:center;
  margin:30px 0 0;
}
.rates-cta-info h4 {
  color:#03873d;
  font-size:20px;
  font-weight:bold;
  text-align:center;
  margin:0 0 10px;
}
.rates-cta-info hr {
  width:300px;
}
.rates-cta-info p {
  color:#222222;
  text-align:center;
  margin:6px 0;
}
/* Generic Rates */

.genericRates {
  padding:50px 0;
  position:relative;
}
.genericRates .container {
  z-index: 3;
  position: relative;
}
.genericRates h2 {
  text-align: center;
}
.genericRates .apy-flex {
  display:flex;
  gap:50px;
  margin-top:30px;
}
.relative {
  position: relative;
}
.overlay {
  background-color: rgba(0, 0, 0, .65);
  position:absolute;
  top:0;
  height:100%;
  width:100%;
}
.apy-flex .apy-box {
  width:100%;
  text-align:center;
  padding:20px;
  background-color:#fff;
  box-shadow: 10px 10px 12px rgba(34, 34, 34, .17);
}
.quote {
  text-align: center;
  border-top:3px solid #de7205;
  border-bottom:3px solid #de7205;
  margin:75px auto;
  max-width:900px;
}
.quote-inner {
  padding:30px 0;
}
.quote-inner h3 {
  color:#03873d;
}
.quote-youtube img {
  width:50px;
}
/* Video Columns */
.video-wrapper {
  padding:40px 0;
}
.about .video-wrapper {
  background-color:#f9f9f9;
}
.video-box {
  display:flex;
  gap:50px;
  align-items: center;
}
.video-info {
  width:33%;
}
.video-player {
  width:66%;
}
.video-player video {
  width:100%;
}
.video-player .youtube {
  width:100%;
  aspect-ratio: 16 / 9;
}
/* Image CTA Grey Background */
.image-cta-grey {
  background-color:#f9f9f9;
  padding:60px 0;
}
.image-cta-grey-box {
  display:flex;
  gap:50px;
  align-items: center;
}
.image-cta-grey-info {
  width:40%;
}
.image-cta-grey-player {
  width:60%;
}
.image-cta-grey-player img {
  width:100%;
}
.img-cta-container {
  display:flex;
  gap:75px;
  justify-content:center;
  margin-top:50px;
  flex-wrap: wrap;
}
.img-cta-team-item {
  width:calc(25% - 58px);
  border-bottom:4px solid #de7205;
  padding-bottom:25px;
  position:relative;
}
.img-cta-team-image img {
  width:100%;
  aspect-ratio: 1 /1;
  object-fit: cover;
  object-position:top;
}
.img-cta-team-item p {
  font-weight:500;
  margin:0;
}
/* CC Bank News Preview */
.cc-news-preview {
  padding:75px 0;
  background-size:cover;
}
.cc-news-preview-box {
  display:flex;
  gap:20px;
  flex-direction:column;
}
.cc-news-preview-box li {
  list-style-type: none;
  list-style-image: none;
}
.cc-news-preview-inner {
  display: flex;
  box-shadow:0 0 15px rgba(0, 0, 0, .15);
  width:800px;
  margin:0 auto;
}
.cc-news-homepage-inner {
  display: flex;
  box-shadow:0 0 15px rgba(0, 0, 0, .15);
  min-height:380px;
  margin:20px 10px;
}
.cc-news-preview-box li {
  padding-left:0;
}
.cc-news-image {
  width:47%;
  background-size:cover;
  background-position: center;
}
.cc-news-info {
  width:53%;
  padding:20px;
}
.cc-news-page-box {
  display:flex;
  gap:20px;
  flex-wrap:wrap;
}
.cc-news-page-inner .video-player {
  width:100%;
}
.cc-news-page-inner {
  display:flex;
  flex-direction: column;
  width:32%;
  position: relative;
  margin-bottom:30px;
}
.cc-news-overlay {
  position: absolute;
  width:100%;
  height: 100%;
  top:0;
  z-index: 1;
}
.cc-news-overlay a {
  display: flex;
  width: 100%;
  height: 100%;
}
.cc-news-overlay span {
  display:none;
}
.cc-news-page-info {
  width:100%;
}
.cc-news-page-image img {
  width:100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  opacity: 1;
  transition: opacity .5s;
}
.cc-news-page-image {
  background-color:#222222;
  background-image:url('./images/cc_bank_logo_hover.png');
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}
.cc-news-page-inner:hover img {
  opacity: 0;
}
.cc-news-page-inner:hover .cc-news-page-info h4 {
  color: #03873D;
}
.news-story img {
  float:left;
  max-width:400px;
  padding: 10px 20px 20px 0;
}
/* CC Bank Locations */
.cc-locations-box {
  padding:75px 0;
}
.cc-locations-inner {
  width:100%;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: cover;
  padding:50px 0;
}
.cc-locations-innerhtml {
  background-color:#fff;
  display: flex;
}
.cc-locations-flex.left {
  width:40%;
  padding: 50px;
}
.cc-locations-flex.right {
  width:60%;
}
.locations-info-titles {
  padding:8px 0;
  border-bottom:1px solid #f4f4f4;
  cursor: pointer;
}
.locate {
  width:50%;
  display: flex;
  
}
.locations-info {
  display:flex;
}
.left-links {
  width:40%;
}
.right-map {
  width:60%;
}
.locations-info {
  display:none;
}
.locations-info.active {
  display:block;
}
.hours-info {
  background-color:#222;
  color:#fff;
  display: flex;
  padding:20px;
}
.hours-info div {
  width:50%;
}
.locate-info {
  background-color:#fff;
  width:250px;
  min-height:250px;
  border-bottom:6px solid #de7205;
  padding:20px;
  display: flex;
  flex-direction: column;
}
.locate-info p {
  margin:0;
}
.locate-info .btn-green {
  margin: auto 0 0;
}
.item-right {
  justify-content: right;
}
.locations-section {
  margin:50px auto;
}
.location-flex {
  display:flex;
  flex-wrap:wrap;
  gap:20px;
  margin:50px 0;
}
.location-flex .location-item {
  width:32%;
  border-bottom:10px solid #de7205;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
  background-color:#fff;
}
.location-image img {
  width:100%;
  aspect-ratio: 3 / 2;
  object-fit: cover;
}
.location-icon {
  text-align: center;
  margin-top:40px;
}
.location-icon img {
  height:65px;
}
.location-info {
  padding:15px 20px;
  display:flex;
  flex-direction: column;
  min-height:200px;
}
.location-info p {
  margin:0;
}
.location-info .btn-green {
  margin:auto 0 0;
}
.community-giving ul {
  margin:0;
  padding:0 15px;
}
.location-img img {
  width:100%;
}
.left-links div::after {
  content: '\203A';
  display: inline-block;
  float: right;
  font-size:16px;
}
.locations-info-titles.active {
  font-weight:bold;
}
/* Location Details */
.location-details-image {
  height:550px;
  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
}
.location-details-image.sandy {
  background-position: 50% 23%;
}
.location-details-image.salem {
  background-position: 50% 35%;
}
.location-team {
  background-color:#f9f9f9;
  padding:50px 0;
}
.team-container {
  display:flex;
  gap:75px;
  justify-content:center;
  margin-top:50px;
  flex-wrap: wrap;
}
.location-team-image img {
  width:100%;
  aspect-ratio: 1 /1;
  object-fit: cover;
  object-position:top;
  /* object-view-box: inset(1% 0 26% 0); */
}
.location-team-item {
  width:calc(33.3% - 50px);
  border-bottom:4px solid #de7205;
  padding-bottom:50px;
  position:relative;
  line-height: 1.6;
}
.location-team-item p {
  margin:0;
  margin-top:-2px;
}
.location-team-item h3 {
  margin-bottom:0;
}
.location-hours {
  display:flex;
  justify-content:center;
  gap:30px;
  padding:75px 50px;
}
.location-hours .hours-item {
  width:50%;
  border:1px solid #707070;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
  padding:25px 50px;
}
/* Team Members Section */
.team-details {
  padding:75px 0; 
  background-color:#f9f9f9;
}
.details-flex {
  display:flex;
  gap:75px;
  align-items: center;
}
.details-image {
  width:45%;
}
.details-info {
  width:55%;
}
.details-info-inner {
  padding-bottom:20px;
  border-bottom:4px solid #de7205;
}
.details-image img {
  width:100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: top;
}
.leadership {
  background-color:#f9f9f9;
  padding:50px 0;
}
.team-flex {
  display:flex;
  flex-wrap: wrap;
  gap:40px 75px;
  margin-top:50px;
}
.team-flex .team-flex-item {
  width:20%;
  border-bottom:4px solid #de7205;
  position: relative;
}
.item-content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  width:100%;
  height:240px;
}
.item-content:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.hover-button {
  position: absolute;
  bottom:20px;
  left:50%;
  transform: translate(-50%, 0);
  opacity: 0; /* Initially hide the button */
  transition: opacity 0.3s ease; /* Add smooth transition effect */
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  display: inline-block;
  text-wrap: nowrap;
  cursor:pointer;
}
.item-content:hover .hover-button {
  opacity:1;
}
.team-flex .team-flex-item h4 {
  color: #03873d;
  margin-bottom:0;
}
.team-flex .team-flex-item p {
  font-size:14px;
  margin-top:0;
  margin-bottom:30px;
}
.team-flex .team-img img {
  width:100%;
}
.bio-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.bio-container {
  background: white;
  padding: 20px;
  max-width:800px;
}

.close-button {
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 10px;
}

.bio {
  color: black;
  display:flex;
}
.bio-image {
  width:30%;
  padding-right:20px;
}
.bio-info {
  width:70%;
}
.bio-info h4 {
  margin-top:0;
}
.bio-info hr {
  margin:0 0 15px;
}
.bio-info p {
  margin-bottom:15px!important;
}
.bio-image img {
  width:100%;
}
.link-overlay {
  position: absolute;
  width:100%;
  height:100%;
  display: flex;
}
.link-overlay a {
  display:block;
  width:100%;
  height:100%;
}
.link-overlay span {
  display:none;
}
/* Contact Form */
.contact-form {
  padding:20px 50px 30px;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
  border-bottom:6px solid #de7205;
  margin-top:75px;
}
.form-input {
  display: flex;
  flex-direction: column;
}
.form-input label {
  font-size:14px;
  font-weight: bold;
  margin-top: 10px;
}
.form-input input, .form-input textarea {
  background-color:#fff;
  border:2px solid #D9D9D9;
  border-radius:2px;
  margin:10px 0;
  padding:15px;
}
button.btn-green {
  background-color:#03873D;
  border-radius:2px;
  width:100%;
  display:inline-block;
  text-align: center;
  color:#fff;
  font-weight:bold;
  padding:14px 0;
  border:0;
  cursor: pointer;
  text-transform: uppercase;
  margin-top:30px;
}
/* Holiday Schedule */
.holidays {
  background-color:#f9f9f9;
  padding:50px 0;
}
.holidays-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:20px 0;
}
.holiday-item {
  display:flex;
  width:500px;
}
.holiday-item .h-date {
  width:24%;
  padding:8px 0;
}
.holiday-item .h-day {
  width:20%;
  padding:8px 0;
}
.holiday-item .h-name {
  font-weight:bold;
  width:56%;
  padding:8px 0;
}
/* Rates Page */
.rates-table {
  margin:50px auto;
}
.rates-table table {
  width:100%;
  border-collapse:collapse;
}
.rates-table th {
  background-color:#707070;
  text-align:left;
  color:#fff;
}
th.orange-header {
  background-color:#de7205;
}
.rates-table tr {
  border-bottom:1px solid #707070;
}
.rates-table td {
  background-color:#f9f9f9;
}
.rates-table td:nth-of-type(even){
  background-color:#fff;
}
.rates-table th:nth-of-type(even){
  background-color:#707070;
}
.rates-table td, th {
  padding:10px 20px;
}
.rates-table td:first-of-type {
  width:20%;
}
.rates-table td:nth-of-type(2) {
  width:30%;
}
.rates-table td:nth-of-type(3) {
  width:25%;
}
.rates-table td:nth-of-type(4) {
  width:25%;
}
/* Commercial Banking Product Comparison Table */
.bold {
  font-weight: bold;
}
.cc-bank-table {
  padding:50px 0;
}
.cbpc {
  padding:10px;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
}
.cbpc table caption {
  background-color:#222222;
  color:#fff;
  padding:8px 0;
  font-size:28px;
  font-weight: bold;
}
.cbpc th {
  background-color:#de7205;
  color:#fff;
  font-weight:bold;
}
.cbpc tr:nth-of-type(odd) {
  background-color:#f9f9f9;
}
.cbpc td {
  padding:20px;
}
.cbpc td div {
  padding: 8px 0;
}
.cbpc span {
  font-size:16px;
}
.table-flex-col {
  display:flex;
  flex-direction: row;
  font-weight: bold;
}
.table-flex-col div:first-of-type {
  width:40%;
}
.table-flex-col div {
  width:20%;
}
/* QA Section */
.qa-item .question {
  border:1px solid #222222;
  border-bottom:0;
  padding:10px 40px;
  cursor: pointer;
}
.qa-item .question::marker {
  text-align:right!important;
}
.qa-item:last-of-type {
  border-bottom:1px solid #222222;
}
.qa-item .answer {
  padding:10px 40px;
  background-color:#f9f9f9;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}
.qa-container {
  margin:50px 0;
}
details>summary {
 
  list-style: none;
}
summary::-webkit-details-marker {
  display: none
}

summary::after {
  content: '\203A';
  display: inline-block;
  transform: rotate(-90deg);
  float: right;
  font-size:20px;
}
details[open] summary:after {
  content: "\203A";
  transform: rotate(90deg);
  display: inline-block;
}
.calculators {
  background-color:#f9f9f9;
  padding:50px 0;
}
.sgi-header__title {
  text-align:center;
  color:#de7205;
  font-size:36px;
  font-family: 'Fjalla One', sans-serif;
  margin:15px 0;
}
/* Business Banking */
.businessBanking .hero {
  background-position: 50% 30%;
}
.member-slider {
  margin-bottom:50px;
}
.member-slider ul {
  list-style-type: none;
}
.member-slider ul li {
  list-style-image: none;
}
.member-slider .slider {
  display:flex;
  gap:40px;
}
.slider-member {
  border-bottom:4px solid #de7205;
  position:relative;
}
.slider-image img {
  width:100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: top;
}
.feedback ul {
  padding:20px 0;
}
/* Background-left-text */
.background-left-text {
  min-height:600px;
  background-position: center;
  background-size:cover;
  position: relative;
  display: flex;
  align-items: center;
}
.background-left-text-text {
  z-index: 2;
  position: relative;
  width:100%;
}
.agreement {
  background-position: center;
  background-size: cover;
  position: relative;
}
.background-center-text {
  min-height:600px;
  background-position: center;
  background-size:cover;
  position: relative;
  display: flex;
  align-items: flex-end;
}
/* Box CTAs */
.grey-box {
  background-color:#f9f9f9;
  padding:50px 0;
}
.box-cta {
  display:flex;
  position: relative;
  gap:20px;
  margin:30px auto;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row {
  flex-direction:column;
}
.box-item {
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
  width:100%;
  padding:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:#fff;
}
.feedback-item {
  box-sizing: border-box;
  height:100%;
}
.flex-row .box-item {
  align-items:flex-start;
}
.box-item h5 {
  font-size:14px;
  margin:0;
}
.box-item h4 {
  margin:10px 0 0;
  font-size:19px;
}
.bmca h3 {
  margin:20px 0 0;
}
.bmca h2 {
  margin:0;
}
.bmca h5 {
  margin-bottom:20px;
}
.bmca h4 {
  margin-bottom:20px;
}
.partners-section .box-item {
  width:28%;
  align-items: center;
  justify-content:center;
}
.partners-section .choicecash-for-business {
  width:61%;
}
.partners-section .box-item img {
  width: 100%;;
}
/* Right Image CTA */
.right-image-cta {
  display: flex;
}
.ric-image {
  width:55%;
  display: flex;
  align-items: center;
}
.ric-image img {
  width:100%;
}
.ric-info {
  width:45%;
}
.ric-info p {
  padding-right:40px;
}
.ric-info ul {
  padding-left:12px;
}
/*Loan Officers */
.loan-officers:nth-of-type(even) {
  background-color:#F0F0F0;
}
.sbateam {
  background-color:#fff;
}
.loan-officers-inner {
  padding:30px;
}
.lo-item {
  display:flex;
  padding:30px 0 0;
}
.lo-info {
  display:flex;
  gap:20px;
  width:45%;
  padding-right:20px;
  align-items: center;
}
.lo-bio {
  width:55%;
  padding-left:30px;
  border-left:#de7205 2px solid;
}
.lo-image {
  width:150px;
}
.lo-image img {
  width:100%;
  aspect-ratio: 1 /1;
  object-fit: cover;
  object-position:top;
}
.lo-contact h4 {
  margin:0;
}
.lo-contact p {
  margin-bottom:0;
}
.lo-bio {
  display:flex;
  align-items: center;
}
/* Login Hero Box */
.login-wrapper {
  position: relative;
  max-width: 1500px;
  margin:0 auto;
}
.login-containter {
  z-index: 2;
  width:300px;
}
.banner-slider .login-containter {
  position:absolute;
  top:85px;
  left:100px;
}
.login-container {
  z-index: 2;
  width:370px;
}
.accordion-title {
  background-color:#03873D;
  color:#fff;
  padding:10px;
  text-align: center;
  border-bottom:1px solid #fff;
  cursor: pointer;
  font-weight:700;
}
/* .commercial-banking .accordion-title {
  background-color:#fff;
  color:#222222;
} */
.credit-card .accordion-title {
  background-color:#fff;
  color:#222222;
}
.accordion-item {
  margin-bottom:4px;
  background-color:#fff;
}
.accordion-body {
  transition: height .3s; /* Use max-height for transition */
  height: 250px; /* Set a large enough max-height */
  overflow: hidden;
  position: relative;
}
.accordion-body form {
  padding:20px;
}
.accordion-body input {
  border:1px solid #aaa;
}
form.login-fields {
  display: flex;
  flex-direction: column;
}
.login-button-group {
  list-style-type: none;
  padding:0;
  margin:0;
}
.login-button-group li {
  list-style-image:none;
  padding-left:0;
}
.accordion-body.inactive {
  height: 0;
  overflow: hidden;
}
.accordion-body.active {
  height: 250px;
}
.inactive .accordion-title {
  background-color:#DEDEDE;
  color:#000;
}
.cid-messg {
  font-size:14px;
  padding-bottom:10px;
}
.cclogin {
  border:0;
}
.cclogin a {
  color:#222222;
}
.login-buttons {
  padding:0 20px;
}
.account-button {
  background-color:#03873D;
  color:#fff;
  position: absolute;
  bottom:0;
  width:100%;
  text-align: center;
}
.account-button a {
  color:#fff;
  display: inline-block;
  width:100%;
  padding:8px;
}
/* Form Style */
input {
  background-color:#f9f9f9;
  border:0;
  padding:8px;
  margin:4px 0;
}
input[type=submit] {
  background-color:#03873D;
  color:#fff;
  cursor: pointer;
}
/* SBA Loans */
.sbaloans-intro {
  display:flex;
  align-items: center;
  gap:30px;
}
.sba-text {
  width:50%;
}
.sba-image {
  width:50%;
}
.sba-image img {
  width:100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  object-fit: cover;
}

/* Footer Styling */
.footer {
  background-color:#222;
  border-top:6px solid #de7205;
  padding:75px 0 0;
}
.footer-logo-area {
  display:flex;
  align-items: center;
}
.footer-logo-area img {
  width:175px;
}
.footer-top-line {
  display:flex;
  justify-content: center;
  gap:50px;
}
.footer-social img {
  width:33px;
  margin:0 4px;
}
.footer-social {
  display:flex;
  align-items: center;
  padding:0 50px 0 5px;
}
.awards {
  display:flex;
  align-items: center;
  gap:10px;
}
nav.footer-nav {
  display: flex;
  align-items:center;
}
nav.footer-nav ul {
  margin:0;
  padding:0;
  list-style-type: none;
  display:flex;
  align-items: center;
}
nav.footer-nav ul li {
  padding:0 15px;
  border-right: 1px solid #de7205;
}
nav.footer-nav ul li.footer-last {
  border-right:0;
}
nav.footer-nav ul li a {
  color:#fff;
  font-weight:bold;
}
.footer-email h4 {
  color:#fff;
  margin:0;
  font-size:14px;
}
.footer-email input {
  background-color:transparent;
  border:1px solid #fff;
  border-radius:0;
  color:#fff;
}
.signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-email form {
  display: flex;
}

.footer-email input[type="email"] {
  padding: 10px;
  border-right:0;
}

.footer-email button {
  padding:0 10px;
  background-color: transparent;
  border: 1px solid #fff;
  border-left:0;
  color: #FFF;
  cursor: pointer;
  margin:4px 0;
}
.footer-middle-line {
  text-align:center;
  color:#fff;
  margin-bottom:40px;
}
.footer-middle-line span {
  padding:0 30px;
}
.footer-middle-line span.footer-last {
  border-right:0;
}
.footer-middle-line span.footer-last img {
  width:20px;
  padding-right:8px;
}
.footer-middle-line span.grey {
  color:#808285;
  border:0;
}
.footer-bottom-line {
  text-align:center;
  margin-bottom:10px;
}
.footer-bottom-line ul {
  list-style-type: none;
  margin:0;
  padding:0;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:30px;
}
.footer-bottom-line ul li {
  padding:0 15px;
  color:#fff;
  font-weight:bold;
}
.footer-app-icons-container h4 {
  font-size:14px;
}
.footer-app-icons {
  display:flex;
  gap:8px;
}
.footer-app-icons img {
  width:100px;
}
.footer-app-links {
  display:flex;
  justify-content: center;
  align-items: center;
  gap:50px;
}
.footer-app-links h4 {
  color:#fff;
}
.footer-app-links a {
  color:#fff;
  padding:4px 0;
  display:inline-block;
}
.footer-copy {
  background-color:#111;
  text-align: center;
  padding:10px;
}
.footer-copy p {
  color:#777777;
  margin:0;
  font-size:14px;
  display:flex;
  justify-content: center;
}
.footer-copy span {
  padding-left:10px;
}
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background-color: #03873Dff;
  color:#fff;
  padding-bottom:4px;
  z-index: 10;
  border-radius: 50px;
  width: 34px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;

}
.breadcrumbs {
  font-size:14px;
}
.breadcrumbs span {
  padding:0 6px;
}
.flexbox {
  display:flex;
}
.flexbox > div {
  width:50%;
}
.maximize {
  align-items: center;
}
#zelle-hero-id-51 {
  background-position:50% 15%;
}
.app-icons .right {
  margin-right:10px;
}
.app-icons img {
  height:43px;
  width:auto;
}
/* Add these styles in your CSS file */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}
.modal-main-content {
  padding:20px;
}
.modal-content button {
  margin: 10px;
  padding: 8px 15px;
  border: none;
  background-color: #03873D;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #de7205;
}
.modal-content .top {
  background-color: #DEDEDE;
  padding:2px;
  border-radius: 5px 5px 0 0;
}
button span {
  display:none;
}
.steps {
  display:flex;
  width:100%;
  gap:20px;
}
.step {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding:30px;
  width:100%;
  box-shadow: 0 0 12px rgba(34, 34, 34, .15);
  display:flex;
  align-items: center;
}
.step span {
  font-size:50px;
  color:#03873D;
  font-weight:bold;
  padding-right:20px;
}
.line-with-circles {
  display: flex; /* Use flexbox to align items */
  justify-content: center; /* Center the items horizontally */
  align-items: center; /* Align the items vertically */
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  width:350px;
}

.line-with-circles::before,
.line-with-circles::after {
  content: ""; /* Required for pseudo-elements */
  border: 2px solid #03873D;; /* Outline of the circle */
  border-radius: 50%; /* Make the shape a circle */
  width: 10px; /* Diameter of the circle */
  height: 10px; /* Diameter of the circle */
  position: absolute; /* Position absolutely within the div */
}

.line-with-circles::before {
  left: 0; /* Align to the left */
}

.line-with-circles::after {
  right: 0; /* Align to the right */
}

.line-with-circles::after,
.line-with-circles::before {
  background-color: transparent; /* Ensure the circle is only an outline with no fill */
}

.line-with-circles::after {
  /* Add more specific styling if needed */
}

.line {
  flex-grow: 1; /* Allow the line to take up available space */
  height: 2px; /* Thickness of the line */
  background-color: #03873D;; /* Color of the line */
  margin: 0 12px; /* Space between circles and the line */
}
.footer-ribbon {
  max-width:100px;
}
.QSIFeedbackButton button > div {
  background-color: rgb(28, 160, 83) !important;
}
@media (min-width:992px) {
  #loanoff-hero-id-14 {
    background-position:50% 17%;
  }
  #sba-hero-id-19 {
    background-position:50% 10%;
  }
}
@media (max-width:1400px) {
  .hero-image img {
    max-width:350px;
  }
}
@media (max-width:1200px) {
  .footer-social img {
    margin:0 10px;
  }
  nav.footer-nav ul li {
    padding: 0 15px;
  }
  .hero-mobile {
    display:block;
  }
  .hero-image {
    display:none;
  }
}
@media (max-width:992px) {
  * {
    box-sizing: border-box;
  }
  .container {
    padding-left:30px;
    padding-right:30px;
  }
  .pd-tb-30 {
    padding-top:30px;
    padding-bottom:30px;
  }
  .iconcta {
    width:calc(50% - 40px);
  }
  .team-flex .team-flex-item {
    width:calc(50% - 40px);
  }
  .location-flex .location-item {
    width:calc(50% - 15px);
  }
  .team-container {
    flex-wrap: wrap;
  }
  .image-cta-grey-box {
    flex-direction: column;
  }
  .image-cta-grey-info {
    width:100%;
  }
  .image-cta-grey-player {
    width: 100%;
    order:-1;
  }
  .right-image-cta {
    flex-direction: column;
  }
  .ric-info {
    width:100%;
    margin-top:30px;
  }
  .ric-image {
    width:100%;
    order:-1;
  }
  .lo-item {
    flex-direction:column;
  }
  .lo-info {
    width:100%;
  }
  .lo-bio {
    width:100%;
    border-left:0;
    padding-left:0;
    border-bottom:#de7205 2px solid;
  }
  .sbaloans-intro {
    flex-direction: column;
  }
  .sba-text {
    width:100%;
  }
  .sba-image {
    width:100%;
  }
  .cc-news-page-inner {
    width:calc(50% - 15px);
  }
  .img-cta-team-item {
    width:calc(50% - 40px);
  }
  .partners-section .box-item {
    width:48%;
    align-items: center;
    justify-content:center;
  }
  .partners-section .choicecash-for-business {
    width:98%;
  }
  .footer-top-line {
    flex-direction: column;
    align-items: center;
    gap:20px;
    margin-bottom:20px;
  }
  .footer-logo-area {
    padding-right:0;
    border-right:0;
    border-bottom:1px solid #fff;
    padding:15px 30px;
  }
  .footer-social {
    padding:0;
    margin:20px 0;
  }
  .footer-app-links {
    flex-direction: column;
    gap:20px;
  }
  .line-with-circles {
    display:none;
  }
}
@media (max-width: 768px) {
  .mg-auto {
    padding-left:30px;
    padding-right:30px;
  }
  .news-story img {
    max-width:100%;
    width:100%;
    float:none;
  }
  .cc-news-page-inner {
    width:100%;
  }
  .box-cta {
    flex-direction: column;
  }
  .details-flex {
    gap:15px;
  }
  .location-hours {
    flex-wrap: wrap;
  }
  .location-hours .hours-item {
    width:100%;
  }
  .location-team-item {
    width:calc(50% - 40px);
  }
  .holiday-item {
    width:100%;
  }
  .location-flex .location-item {
    width:100%;
  }
  .iconcta {
    width:100%;
  }
  .col-2 .cta-box .cta {
    width:100%;
  }
  .col-3 .cta-box .cta {
    width:100%;
  }
  .login-containter {
    display:none;
  }
  .login-container {
    display:none;
  }
  .account-login .login-containter {
    display:block;
  }
  .inner-hero {
    width:100%;
    max-width: 100%!important;
  }
  .alt-cta {
    flex-direction: column;
  }
  .alt-cta-image {
    order:-1;
    width:100%;
  }
  .alt-cta-info {
    width: 100%;
  }
  .cta-box {
    flex-direction: column;
  }
  .rates-cta .container {
    flex-direction: column;
  }
  .rates-cta-item {
    width:100%;
  }
  .video-box {
    flex-direction: column;
  }
  .video-player {
    width:100%;
    order:-1;
  }
  .video-info {
    width: 100%;
  }
  .cc-locations-innerhtml {
    flex-direction: column;
  }
  .cc-locations-flex.left {
    width:100%;
    padding:0;
  }
  .cc-locations-flex.right {
    width:100%;
  }
  .hours-info {
    flex-direction: column;
  }
  .right-map {
    width:100%;
  }
  .right-map iframe {
    width: 100%;
  }
  .cc-locations-inner {
    padding:0;
  }
  .cc-news-preview-inner {
    flex-direction: column;
    width:100%;
  }
  .cc-news-homepage-inner {
    flex-direction: column;
 
  }

  .cc-news-image {
    min-height:300px;
    width:100%;
  }
  .cc-news-info {
    width:100%;
  }
  .looking-for .react-multi-carousel-dot-list {
    flex-direction: column;
  }
  .looking-for .react-multi-carousel-track {
    margin-top:270px;
  }
  .looking-item {
    flex-direction: column;
    padding:0 30px;
  }
  .look-info {
    width:100%;
  }
  .look-image {
    width:100%;
  }
  .bio-overlay {
    height: 100vh;
  }
  
  .bio-container {
    height: 100vh;
    overflow: scroll;
  }
  .bio {
    flex-direction: column;
  }
  .bio-image, .bio-info {
    width:100%;
    margin-top:30px;
  }
  
  
  nav.footer-nav ul {
    flex-direction: column;
  }
  .footer-middle-line {
    display:flex;
    flex-direction: column;
  }
  .footer-middle-line span {
    padding:10px 0;
  }
  .footer-bottom-line ul {
    flex-direction: column;
  }
  #sba-hero-id-19 {
    background-position:75% 50%;
  }
  #personal-landing-hero-id-43 {
    background-position:70% 50%;
  }
  #deposit-acounts-hero-id-33 {
    background-position:75% 50%;
  }
  #lendio-hero-id-67 {
    background-position:75% 10%;
  }
  .hero-banner #hero-id-8 {
    background-position:75% 10%;
  }
  .partners-section .box-item {
    width:100%;
    align-items: center;
    justify-content:center;
  }
  .partners-section .choicecash-for-business {
    width:100%;
  }
  .cbpc, .rates-table {
    overflow:scroll;
  }
  .footer-middle-line span.footer-last img {
    width:25px;
  }
  .steps {
    flex-direction: column;
  }
  nav.footer-nav ul li {
    border-right:0;
  }
}
@media (max-width: 500px) {
  .team-flex .team-flex-item {
    width:100%;
  }
  .location-team-item {
    width:100%;
  }
  .img-cta-team-item {
    width:100%;
  }
  .item-content {
    height: calc(100% - 95px);
  }
  .cta-box .cta-info {
    padding:45px;
  }
  .cta-info ul {
    padding-left:20px;
  }
  .details-flex {
    flex-direction: column;
  }
  .details-info {
    width:100%;
  }
  .details-image {
    width:100%;
  }
  .custom-home-dot-list-style {
    display:none;
  }
  .hero-banner h1 {
    font-size:30px;
    line-height:40px;
  }
  .btn-green a {
    padding:4px 10px;
    font-size:14px;
    margin-bottom:8px;
  }
  .footer-copy p {
    flex-direction: column;
  }
  .numbers.mg-left-50 {
    margin-left:0!important;
  }
  .news-items h1 {
    font-size:30px;
  }
}

/* Credit Cards */
.credit-cards.cta-box .cta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: 225px;
  padding-bottom: 50px;
  margin-top: 50px;
  text-align: center;
  border-bottom: none;
}

@media (max-width: 768px) {
  .credit-cards.cta-box {
    gap: 50px;
  }
}

.credit-cards.cta-box .cta-image {
  width: 100%;
  top: -50px;
  min-height: 275px;
  background-size: contain;
  background-position-x: center;
  position: absolute;
}

.credit-cards .title h2 {
  color: black;
  font-size: 24px;
  letter-spacing: 2px;
  margin-top: 40px;
  margin-bottom: 30px;
}

.credit-cards .cta-info {
  padding: 0 35px;
}

.credit-cards .cta-info .description {
  text-align: left;
}

.credit-cards .cta-info .description p {
  line-height: 22px;
}

.credit-cards .cta-info .description strong {
  font-weight: 600;
}

.credit-cards .cta-info .description ul {
  margin-top: 0;
  margin-bottom: 30px;
}

.credit-cards .cta-info .description li {
  list-style: disc;
  line-height: 22px;
  padding-left: 0;
}

.credit-cards .btn-green a {
  border-radius: 5px;
  font-size: 22px;
  padding: 15px 45px;
  text-transform: none;
}

h2.thin-text {
  font-size: 34px;
  font-style: normal;
  font-weight: 300;
  color: black;
}

.ctas .cta-box .cta {
  border-bottom: none;
}

.ctas .cta-box .cta-image {
  min-height: 110px;
  max-height: 110px;
}

.ctas .cta-info {
  padding: 20px 70px;
}

.ctas .title h2 {
  color: black;
  font-size: 24px;
}

.ctas .cta-info .description p {
  line-height: normal;
}

.full-width-cta {
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: 0;
  display: flex;
  align-items: center;
}

.full-width-cta .overlay {
  background: linear-gradient(97deg, #2A0101 6.46%, #B90319 94.29%);
  z-index: -1;
}

.full-width-cta .inner-content {
  margin: 50px auto;
}

@media (min-width: 769px) {
  .full-width-cta {
    background-position: 25% -15%;
    background-size: 480px;
    min-height: 540px;
  }  
  .full-width-cta .inner-content {
    margin-left: calc(50% - 50px);
  }
}

.full-width-cta h3 {
  margin-top: 0;
  font-size: 24px;
}

.full-width-cta p {
  line-height: normal;
}

.btn-empty-round a {
  border: 1px solid white;
  border-radius: 30px;
  color: white;
  font-size: 18px;
  line-height: 50px;
  padding: 15px 42px;
}

.link-list {
  padding-left: 0;
  text-align: center;
}

.link-list li {
  display: inline;
  list-style: none;
  padding: 0 15px;
  border-left: 1px solid #3E3E3E;
}

.link-list li:first-child {
  padding-left: 0;
  border-left: none;
}

.link-list li a {
  color: #3E3E3E;
  font-size: 16px;
  line-height: normal;
}
